import React from "react";
import { GothamRegular } from "../../../components/Text";
import { arraySplitter, windowDimension } from "../../../components/tools";
import { CheckBox } from "../../../components/Checkbox";
import { useRegisterField } from "../../../components/inputs";
import { isEmpty } from "lodash";

export const ProductNeeds = ({ useFormObj, name, label, options }) => {
  const { isTabOrPhone: isResponsive } = windowDimension();
  const { watch, setValue } = useFormObj;

  const setVal = (value) => setValue(name, value);

  const values = watch(name) || [];

  const array = arraySplitter(options, 4);

  useRegisterField(useFormObj, name);

  return (
    <div
      className="d-flex"
      style={{
        marginBottom: 32,
        flexDirection: isResponsive ? "column" : "row",
        gap: 20,
      }}
    >
      <GothamRegular className="font12">{label}</GothamRegular>
      <div
        style={{
          display: "flex",
          justifyContent: isResponsive ? "flex-start" : "space-between",
          gap: 20,
        }}
      >
        {array.map((array, index) => {
          return (
            <div
              key={index}
              className="d-flex"
              style={{ flexDirection: "column", gap: 16 }}
            >
              {array.map((item, index) => {
                const { label, value } = item || {};
                const isActive = values.includes(value);
                return (
                  <div
                    className="d-flex hover"
                    key={index}
                    style={{ gap: 8 }}
                    onClick={() => {
                      if (!isActive) return setVal([...values, value]);
                      const filtered = values.filter((val) => value !== val);

                      setVal(isEmpty(filtered) ? undefined : filtered);
                    }}
                  >
                    <CheckBox isActive={isActive} />
                    <GothamRegular>{label}</GothamRegular>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
