import { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { InputActionMeta } from "react-select";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../Text";
import { DropdownIndicator } from "./selection";
import { components } from "react-select";
import { isArray, isEmpty } from "lodash";
import { useClickOutside } from "../../universalFunctions/useClickOutside";

const CreatableJackState = ({
  value: valueProps = {},
  onChange,
  label,
  options: optionsProps = [],
  placeholder,
  error,
  disabled,
  setValue,
}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(0);

  useEffect(() => setOptions(optionsProps), [optionsProps.length]);
  const hasCombined = valueProps?.hasOwnProperty("combinedIndustry");
  const isNewOption = valueProps?.hasOwnProperty("isNew");

  const getNarrowedDownOptions = (types) => {
    const narrowedDownOptions = (types || []).map((type) => {
      const typeName = type?.type;
      const oid = type?._id?.$oid;
      return {
        ...type,
        label: typeName,
        value: oid,
        type: typeName,
      };
    });
    return narrowedDownOptions;
  };

  const narrowedDownOptionsProps = getNarrowedDownOptions(valueProps?.types);

  const OtherMessage = () => {
    return (
      <div
        style={{
          padding: 8,
          display: "flex",
          gap: 8,
          backgroundColor: jackColors.neutral500,
          alignItems: "center",
        }}
      >
        <JackIcons
          name="info-outline"
          style={{ height: 20, width: 20 }}
          fill={jackColors.neutral700}
        />

        <GothamMedium
          className="font12"
          style={{ color: jackColors.neutral800 }}
        >
          Can’t find your business sector? Directly type on the field above!
        </GothamMedium>
      </div>
    );
  };

  const ref = useRef(null);

  const OptionLayout = (props) => {
    const inputted = props?.data?.value || "";
    const isNew = !!optionsProps.find(({ label }) => label === inputted);
    const { isFocused } = props;
    const showArrow = currentLevel === 1 && isFocused && isNew;
    return (
      <components.Option {...props}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div>{props.children}</div>
          {showArrow && (
            <JackIcons
              name="chevron-right"
              fill={jackColors.neutral600}
              style={{ width: 20, height: 20 }}
            />
          )}
        </div>
      </components.Option>
    );
  };

  const MenuList = (props) => {
    const children = isArray(props.children) ? props.children : [];
    const focusedOptionValue = props?.focusedOption?.value || "";

    const isAvailable = !!options.find(({ label = "" }) =>
      label?.includes(focusedOptionValue)
    );

    return (
      <div>
        {!isEmpty(selectedOption) && (
          <div
            onClick={() => handleOptionBack()}
            style={{
              paddingBottom: 16,
              margin: "16px 12px 0px 12px",
              display: "flex",
              alignItems: "center",
              gap: 8,
              borderBottom: `1px solid ${jackColors.neutral500}`,
              cursor: "pointer",
            }}
          >
            <JackIcons
              name="arrow-back"
              style={{ height: 16, width: 16 }}
              fill={jackColors.black34}
            />
            <GothamMedium className="font14">
              {selectedOption?.value}
            </GothamMedium>
          </div>
        )}
        <components.MenuList {...props}>{children}</components.MenuList>
        {isAvailable && <OtherMessage />}
      </div>
    );
  };

  const SingleValue = ({ data, ...props }) => {
    const isNew = valueProps?.hasOwnProperty("isNew");
    const level1 = hasCombined
      ? valueProps?.industry_category || valueProps?.value
      : data?.name || data?.value;

    let singleVal = "";

    if (isNew) {
      singleVal = `Other (${valueProps.value || ""})`;
    } else if (currentLevel === 2 && !hasCombined) {
      singleVal = level1;
    } else {
      singleVal = valueProps?.combinedIndustry;
    }

    return (
      <components.SingleValue {...props}>
        {singleVal || ""}
      </components.SingleValue>
    );
  };

  const handleNewEntry = (val) => {
    onChange({
      ...val,
      industry_category: selectedOption?.value,
      combinedIndustry: `Other (${val?.value || ""})`,
    });
    setCurrentLevel(0);
    return setMenuIsOpen(false);
  };

  const handleOptionChange = (val) => {
    const isNew = val.hasOwnProperty("isNew");
    if (isNew) {
      return handleNewEntry(val);
    }
    if (currentLevel === 2) {
      onChange({
        ...val,
        industry_category: selectedOption?.value,
        combinedIndustry: `${selectedOption?.value} (${val?.type || ""})`,
        types: selectedOption?.types || [],
      });

      setCurrentLevel(0);
      return setMenuIsOpen(false);
    }

    if (currentLevel === 1) {
      const narrowedOptions = getNarrowedDownOptions(val?.types);

      setSelectedOption({ value: val?.value, types: narrowedOptions } || "");
      // setOptions(otherOptions);
      // setOptions({ ...narrowedOptions, types: val?.types });
      setOptions(narrowedOptions);
      return setCurrentLevel(2);
    }
    // onChange(val?.value)
  };

  const handleOptionBack = () => {
    setCurrentLevel(1);
    setOptions(optionsProps);
    setSelectedOption({});
  };

  const handleClear = () => {
    if (currentLevel !== 0) {
      setCurrentLevel(1);
    }
    setOptions(optionsProps);
    setSelectedOption({});
    setValue("industry", null);
  };

  const handleClickOutside = () => {
    if (!menuIsOpen) return;

    setMenuIsOpen(false);
    // handleClear();
  };

  useClickOutside({
    ref,
    clickOutside: () => handleClickOutside(),
    isOldMethod: true,
    dependency: menuIsOpen,
  });

  return (
    <div
      style={{ marginBottom: 32 }}
      ref={ref}
      onClick={(e) => e.stopPropagation()}
    >
      <GothamRegular
        className="font12"
        style={{
          color: error ? jackColors.redE7 : colors.neutral900,
          marginBottom: 8,
        }}
      >
        {label}
      </GothamRegular>
      <CreatableSelect
        isClearable
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => {
          if (isNewOption) {
            setValue("industry", null);
            setOptions(optionsProps);
          }

          if (hasCombined && !isNewOption) {
            setOptions(narrowedDownOptionsProps);
            setSelectedOption({
              value: valueProps?.industry_category,
              types: valueProps?.types,
            });

            setCurrentLevel(2);
          }
          if (currentLevel === 0 && !hasCombined) {
            setCurrentLevel(1);
            setOptions(optionsProps);
          }
          setMenuIsOpen(true);
        }}
        components={{
          DropdownIndicator,
          MenuList,
          SingleValue,
          Option: OptionLayout,
        }}
        isDisabled={disabled}
        value={
          isNewOption
            ? valueProps
            : options.filter(({ value }) => {
                return value == valueProps;
              })[0]
        }
        onChange={(val) => {
          if (val === null) return handleClear();
          handleOptionChange(val);
        }}
        onCreateOption={(val) => {
          const newObj = { label: val, value: val, isNew: true };

          setOptions((prev) => [...prev, newObj]);
          handleOptionChange(newObj);
        }}
        formatCreateLabel={(text) => (
          <div className="d-flex align-items-center" style={{ gap: 8 }}>
            <JackIcons
              name="plus-circle-outline"
              style={{ height: 20, width: 20 }}
              fill={jackColors.black34}
            />
            <GothamRegular
              className="font12"
              style={{ color: jackColors.black34 }}
            >
              Add "{text}"
            </GothamRegular>
          </div>
        )}
        styles={{
          control: (style, { isFocused }) => ({
            ...style,
            borderRadius: "4px",
            height: "40px",
            fontSize: "14px",
            fontFamily: "GothamBook",
            borderColor: isFocused
              ? colors.neutral900
              : error
                ? jackColors.redE7
                : colors.neutral500,
            boxShadow: "none",
            "&:hover": {
              border: `1px solid ${colors.neutral900}`,
            },

            // ...containerStyle,
          }),
          input: (style) => ({
            ...style,
            fontFamily: "GothamBook",
            // ...inputStyle,
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            fontSize: "14px",
            fontFamily: "GothamBook",
            color: colors.grey6c,
            backgroundColor: isSelected
              ? colors.greye6
              : isFocused
                ? jackColors.greyF1
                : "transparent",
          }),
          menu: (style) => ({
            ...style,
            border: `1px solid ${colors.greye6}`,
            borderRadius: "4px",
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: (style) => ({
            ...style,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "GothamBook",
            color: "#BBBBC0",
          }),
        }}
        placeholder={placeholder}
        options={options}
      />
      {!!error && (
        <GothamRegular
          className="font10"
          style={{
            marginTop: 4,
            color: jackColors.redE7,
          }}
        >
          {error}
        </GothamRegular>
      )}
    </div>
  );
};

export const CreatableJackCustom = ({
  useFormObj,
  name,
  label,
  options,
  placeholder,
  disabled,
}) => {
  const { errors, control, watch, getValues, setValue } = useFormObj;
  const { message: error } = errors[name] || {};

  useEffect(() => {
    const value = watch(name);
    if (!value) return;
    options.push({ label: value, value });
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange }) => (
        <CreatableJackState
          value={value}
          onChange={onChange}
          label={label}
          options={options}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          setValue={setValue}
        />
      )}
    />
  );
};
