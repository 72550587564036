import Turnstile from "react-turnstile";
import { isProduction } from "../../../components/tools";
import { useEffect, useState } from "react";

export const JackTurnstile = ({ onVerify }) => {
  useEffect(() => {
    onVerify(!isProduction);
  }, []);

  if (!isProduction) return null;

  return (
    <div>
      <Turnstile
        size="normal"
        sitekey="0x4AAAAAAANMlwaJnK_kBHqU"
        onVerify={() => onVerify(true)}
        theme="light"
      />
    </div>
  );
};
