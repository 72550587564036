import { CheckBox } from "../../../components/Checkbox";
import { GothamRegular, TextInlineMedium } from "../../../components/Text";
import { useRegisterField } from "../../../components/inputs";
import { windowDimension } from "../../../components/tools";

export const ServiceAgreementCheckbox = ({ useFormObj, name }) => {
  const { isTabOrPhone: isResponsive } = windowDimension();
  const { setValue, watch } = useFormObj;
  useRegisterField(useFormObj, name);

  const value = !!watch(name);
  const onClick = (value) => window.open(`/tnc-login?value=${value}`, "_blank");

  return (
    <div style={{ marginBottom: 32 }} className="d-flex">
      <CheckBox
        isActive={value}
        onClick={() => setValue(name, !value)}
        alignItems={isResponsive ? "align-items-start" : null}
      />
      <GothamRegular style={{ marginLeft: 8 }}>
        I agree to Jack’s{" "}
        <TextInlineMedium
          style={{ textDecorationLine: "underline" }}
          className="hover"
          onClick={() => onClick(1)}
        >
          Service Agreement
        </TextInlineMedium>{" "}
        and {isResponsive && <br />}
        <TextInlineMedium
          style={{ textDecorationLine: "underline" }}
          className="hover"
          onClick={() => onClick(2)}
        >
          Privacy Policy
        </TextInlineMedium>
      </GothamRegular>
    </div>
  );
};
