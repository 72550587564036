import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { colors, jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { GothamRegular } from "../Text";
import { DropdownIndicator } from "./selection";

export const CreatableJackState = ({
  value: valueProps,
  onChange,
  label,
  options: optionsProps,
  placeholder,
  error,
  disabled,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => setOptions(optionsProps), [optionsProps.length]);

  return (
    <div style={{ marginBottom: 32 }}>
      <GothamRegular
        className="font12"
        style={{
          color: error ? jackColors.redE7 : colors.neutral900,
          marginBottom: 8,
        }}
      >
        {label}
      </GothamRegular>
      <CreatableSelect
        components={{ DropdownIndicator }}
        isDisabled={disabled}
        value={options.filter(({ value }) => value == valueProps)[0]}
        onChange={(val) => onChange(val?.value)}
        onCreateOption={(val) => {
          const newObj = { label: val, value: val };

          setOptions((prev) => [...prev, newObj]);
          onChange(val);
        }}
        formatCreateLabel={(text) => (
          <div className="d-flex align-items-center" style={{ gap: 8 }}>
            <JackIcons
              name="plus-circle-outline"
              style={{ height: 20, width: 20 }}
              fill={jackColors.black34}
            />
            <GothamRegular
              className="font12"
              style={{ color: jackColors.black34 }}
            >
              Add "{text}"
            </GothamRegular>
          </div>
        )}
        styles={{
          control: (style, { isFocused }) => ({
            ...style,
            borderRadius: "4px",
            height: "40px",
            fontSize: "14px",
            fontFamily: "GothamBook",
            borderColor: isFocused
              ? colors.neutral900
              : error
              ? jackColors.redE7
              : colors.neutral500,
            boxShadow: "none",
            "&:hover": {
              border: `1px solid ${colors.neutral900}`,
            },

            // ...containerStyle,
          }),
          input: (style) => ({
            ...style,
            fontFamily: "GothamBook",
            // ...inputStyle,
          }),
          option: (style, { isSelected, isFocused }) => ({
            ...style,
            fontSize: "14px",
            fontFamily: "GothamBook",
            color: colors.grey6c,
            backgroundColor: isSelected
              ? colors.greye6
              : isFocused
              ? jackColors.greyF1
              : "transparent",
          }),
          menu: (style) => ({
            ...style,
            border: `1px solid ${colors.greye6}`,
            borderRadius: "4px",
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: (style) => ({
            ...style,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: 14,
            fontWeight: 400,
            fontFamily: "GothamBook",
            color: "#BBBBC0",
          }),
        }}
        placeholder={placeholder}
        options={options}
      />
      {!!error && (
        <GothamRegular
          className="font10"
          style={{
            marginTop: 4,
            color: jackColors.redE7,
          }}
        >
          {error}
        </GothamRegular>
      )}
    </div>
  );
};

export const CreatableJack = ({
  useFormObj,
  name,
  label,
  options,
  placeholder,
  disabled,
}) => {
  const { errors, control, watch } = useFormObj;
  const { message: error } = errors[name] || {};

  useEffect(() => {
    const value = watch(name);
    if (!value) return;
    options.push({ label: value, value });
  }, []);

  return (
    <Controller
      control={control}
      name={name}
      render={({ value, onChange }) => (
        <CreatableJackState
          value={value}
          onChange={onChange}
          label={label}
          options={options}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
        />
      )}
    />
  );
};
