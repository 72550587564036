import { jackColors } from "../../assets/colors";
import { TextInlineRegular } from "../../components/Text";

export const requiredAndOptional = () => {
  const required = (string) => (
    <>
      {string}
      <TextInlineRegular style={{ color: jackColors.redE7 }}>
        *
      </TextInlineRegular>
    </>
  );

  const optional = (string) => (
    <>
      {string}
      <TextInlineRegular style={{ color: jackColors.grey90 }}>
        {" "}
        (optional)
      </TextInlineRegular>
    </>
  );

  return { required, optional };
};
